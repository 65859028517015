export enum MapSize {
	XS = "XS",
	S = "S",
	M = "M",
	L = "L",
}

export interface MapData {
	zoom: number;
	centerLat: number;
	centerLon: number;
}

export interface PerilGroup {
	name: string;
	perils: Peril[];
}

export interface Peril {
	name: string;
	checked?: boolean;
	onClick?: () => void;
	onInfoClick?: () => void;
}

export interface MapLayer {
	name: string;
	children: Children[];
}

export interface AnalysisItem {
	layerId: string;
	valueLabel: string;
	intensity: number;
	details?: {};
}

export interface Raster {
	raster: {
		color: string;
		opacity: number;
	};
}

export interface Polygon {
	polygon: {
		fill: string;
		fillOpacity: number;
	};
}

export interface Rule {
	title: string;
	symbolizers: (Raster | Polygon)[];
}

export interface LegendItem {
	layerId: string;
	rules: Rule[];
	title: string;
}

export interface AnalysisData {
	legendItems: LegendItem[] | string;
	analysisItems: AnalysisItem[] | string;

}

export interface Children {
	id: string;
	name: string;
	group: string;
	category: string;
	description: string;
	checked?: boolean;
	onClick?: () => void;
	onInfoClick?: () => void;
	layerObj?: any;
	layerObjEn?: any;
	layerObjCn?: any;
	oversea?: boolean;
}

export interface LocationData {
	id: string;
	name: string;
	address?: string;
	country?: string;
	oversea?: boolean;
	spatial?: string;
	createTime?: string;
	items?: any[];
	code?: string;
	itemCount?: number;
	tiv: number;
	lat: number;
	lon: number;
	checked: boolean;
}

// export interface layerList {
//   id: string;
//   group: string;
//   category: string;
//   description: string;
//   name: string;
// }

export interface LegendData {
	layerId: string;
	title: string;
	subtitle: string;
	rules?: any;
	layerInfo: Children;
}

export interface crsLayer {
	id: string;
	name: string;
	description: string;
}

export interface locationSourceOption {
	label: string;
	value: string;
}
