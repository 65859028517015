import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./import-menu.module.scss";
import Menu from '@mui/material/Menu';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from "components/button/button";

function ImportMenu() {
    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();
    const [locationSource, setLocationSource] = useState('latlng');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocationSource((event.target as HTMLInputElement).value);
    };

    const handleClose = () => {
        dispatch({ type: AppAction.setLocationSource, payload: undefined });
        dispatch({ type: AppAction.setImportMenu, payload: false });
    };

    const handleUploadFile = () => {
        dispatch({ type: AppAction.setImportMenu, payload: false });
        dispatch({ type: AppAction.setLocationSource, payload: locationSource });
        dispatch({ type: AppAction.setImportModal, payload: true });
    };

    return (
        <Menu
            open={context.importMenu}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    position: 'fixed !important',
                    bottom: '8px !important',
                    left: '64px !important',
                    '& .MuiList-root': {
                        py: 0,
                    },
                    top: "auto !important",
                    right: "auto !important",
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
                },
            }}
        >
            <div className={styles.importMenuBody}>
                <div className={styles.importMenuInfo}>
                    <h6 >{t("locations.importPreferences")}</h6>
                    <div>
                        <p>{t("locations.importPreferencesInfo")}</p>
                    </div>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={locationSource}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="latlng"
                                style={{ marginRight: '0px', marginBottom: '18px' }}
                                control={<Radio color="success" />}
                                label={
                                    <div className={styles.importRadioLabel}>
                                        <div>{t("locations.useCoord")}</div>
                                        <div>{t("locations.useCoordInfo")}</div>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                value="address"
                                style={{ marginRight: '0px', marginBottom: '18px' }}
                                control={<Radio color="success" />}
                                label={
                                    <div className={styles.importRadioLabel}>
                                        <div>{t("locations.useAddress")}</div>
                                        <div>{t("locations.useAddressInfo")}</div>
                                    </div>}
                            />
                        </RadioGroup>
                    </FormControl>
                    <a
                        href={`${process.env.PUBLIC_URL}/${context.language === 'en' ? "location-set-import-template.xlsx" : "地点集导入模版.xlsx"}`}
                        target="_blank"
                        rel="noopener noreferrer" style={{ color: 'var(--green)' }}>{t("locations.modal.link")}</a>
                </div>

                <div className="border-t-[1px] border-solid border-[#0000001f]"></div>
                <Button type="secondary" style={{ fontSize: "14px", fontWeight: "500", height: "52px", }} onClick={handleUploadFile}>{t("locations.buttons.uploadFile")}</Button>
            </div>
        </Menu>
    );
}

export default ImportMenu;
