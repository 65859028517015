import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Icon from "components/icon/icon";
import { LeftPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel, getMultipleLocations } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./add-location.module.scss";
import { addLocationsetsItems, patchLocationsetsItems } from "api/layer"
import LocationSet from "containers/location-set/location-set";

export default function AddLocation() {

	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const options = context.multipleLocations || [];
	let LocationSetData = {
		locationSetId: "",
		code: "",
		name: "",
		country: "",
		county: "",
		longitude: "",
		latitude: "",
		number: "",
		place: "",
		province: "",
		street: "",
		zipCode: ""
	};
	if (context.leftPanel === LeftPanel.AddLocation) {
		LocationSetData = {
			locationSetId: context.currentMultipleLocations ? context.currentMultipleLocations.id : "",
			code: "",
			name: "",
			country: context.searchLocationInfo ? context.searchLocationInfo[context.language].country : "",
			county: context.searchLocationInfo ? context.searchLocationInfo[context.language].district : "",
			longitude: context.searchLocationInfo ? context.searchLocationInfo.location.split(",")[0] : "",
			latitude: context.searchLocationInfo ? context.searchLocationInfo.location.split(",")[1] : "",
			number: context.searchLocationInfo ? (typeof context.searchLocationInfo[context.language].streetNumber.number === "string" ? context.searchLocationInfo[context.language].streetNumber.number : "") : "",
			place: context.searchLocationInfo ? context.searchLocationInfo[context.language].formatted_address : "",
			province: context.searchLocationInfo ? context.searchLocationInfo[context.language].province : "",
			street: context.searchLocationInfo ? (typeof context.searchLocationInfo[context.language].streetNumber.street === "string" ? context.searchLocationInfo[context.language].streetNumber.street : "") : "",
			zipCode: context.searchLocationInfo ? context.searchLocationInfo[context.language].adcode : ""
		}
	} else {
		LocationSetData = { ...context.editMultipleLocation, locationSetId: context.currentMultipleLocations ? context.currentMultipleLocations.id : "", }
	}

	const [LocationSet, setLocationSet] = useState(LocationSetData);
	const [disabled, setDisabled] = useState(false);
	const [isError, setIsError] = useState(false);

	const save = async () => {
		setDisabled(true);
		if (!(LocationSet.locationSetId && LocationSet.code && LocationSet.name && LocationSet.longitude && LocationSet.latitude)) {
			setDisabled(false);
			setIsError(true)
			return
		}
		setIsError(false)
		if (context.leftPanel === LeftPanel.AddLocation) {
			try {
				let res = await addLocationsetsItems(context.currentMultipleLocations!.id, LocationSet);
				context.multipleLocations?.forEach(item => {
					if (item.id === res.data.locationSetId) {
						if (item.items === null) {
							item.items = [res.data]
						} else {
							item.items?.push(res.data)
						}
					}
				})
				dispatch({ type: AppAction.setMultipleLocations, payload: context.multipleLocations });
			}
			catch (error) {
				setDisabled(false);
			}
		} else {
			try {
				let res = await patchLocationsetsItems(context.currentMultipleLocations!.id, context.editMultipleLocation.id, LocationSet)
				context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map(item => {
					if (item.id === context.editMultipleLocation.id) {
						item = { ...item, ...res.data }
					}
					return item
				})
				let multipleLocations = context.multipleLocations?.map(item => {
					if (item.id === context.currentMultipleLocations!.id) {
						item.items = context.currentMultipleLocations!.items
					}
					return item
				})
				console.log(multipleLocations);
				
				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: context.currentMultipleLocations })
				dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
				dispatch({ type: AppAction.setEditMultipleLocation, payload: null });
			}
			catch (error) {
				setDisabled(false);
			}
		}
		setDisabled(false);
		setLeftPanel(dispatch, LeftPanel.Locations);
	};

	const handleChange = (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;
		setLocationSet(prevData => ({ ...prevData, [name]: value }));
	};

	const cancel = () => {
		setLeftPanel(dispatch, LeftPanel.Locations);
		dispatch({ type: AppAction.setEditMultipleLocation, payload: null });
	};

	return (
		<div className={styles.add}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div className="flex items-center">
						<Icon icon="arrow_back" onClick={cancel} />
						<span>{context.leftPanel === LeftPanel.EditLocation ? t("locations.add.editTitle") : t("locations.add.title")}</span>
					</div>
					<div>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<Stack spacing={{ xy: 1, sm: 2 }}>
					<FormControl fullWidth>
						<InputLabel required >{t("locations.add.Location_set")}</InputLabel>
						<Select
							required
							name="locationSetId"
							value={LocationSet.locationSetId}
							disabled={context.leftPanel === LeftPanel.EditLocation}
							onChange={handleChange}
							error={isError && !LocationSet.locationSetId}
							label={t("locations.add.Location_set")}
						>
							{options.map((option, index) => (
								<MenuItem key={index} value={option.id}>
									{option.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						required
						name="code"
						value={LocationSet.code}
						onChange={handleChange}
						error={isError && !LocationSet.code}
						label={t("locations.add.ID")}
						variant="outlined"
					/>
					<TextField
						required
						name="name"
						value={LocationSet.name}
						onChange={handleChange}
						error={isError && !LocationSet.name}
						label={t("locations.add.Name")}
						variant="outlined"
					/>
					<Stack
						spacing={{ xs: 1 }}
						direction="row"
						useFlexGap
						sx={{ flexWrap: "wrap" }}
					>
						<TextField
							required
							sx={{ flex: 1 }}
							name="longitude"
							value={LocationSet.longitude}
							onChange={handleChange}
							error={isError && !LocationSet.longitude}
							label={t("locations.add.Longitude")}
							variant="outlined"
						/>
						<TextField
							required
							sx={{ flex: 1 }}
							name="latitude"
							value={LocationSet.latitude}
							onChange={handleChange}
							error={isError && !LocationSet.latitude}
							label={t("locations.add.Latitude")}
							variant="outlined"
						/>
					</Stack>

					<TextField
						required
						name="zipCode"
						value={LocationSet.zipCode}
						onChange={handleChange}
						label={t("locations.add.zipCode")}
						variant="outlined"
					/>
					<Stack
						spacing={{ xs: 1 }}
						direction="row"
						useFlexGap
						sx={{ flexWrap: "wrap" }}
					>
						<TextField
							required
							sx={{ flex: 1 }}
							name="street"
							value={LocationSet.street}
							onChange={handleChange}
							label={t("locations.add.streetName")}
							variant="outlined"
						/>
						<TextField
							required
							sx={{ flex: 1 }}
							name="number"
							value={LocationSet.number}
							onChange={handleChange}
							label={t("locations.add.streetNumber")}
							variant="outlined"
						/>
					</Stack>
					<TextField
						required
						name="place"
						value={LocationSet.place}
						onChange={handleChange}
						label={t("locations.add.place")}
						variant="outlined"
					/>
					<TextField
						required
						name="county"
						value={LocationSet.county}
						onChange={handleChange}
						label={t("locations.add.county")}
						variant="outlined"
					/>
					<TextField
						required
						name="province"
						value={LocationSet.province}
						onChange={handleChange}
						label={t("locations.add.province")}
						variant="outlined"
					/>
					<TextField
						required
						name="country"
						value={LocationSet.country}
						onChange={handleChange}
						label={t("locations.add.country")}
						variant="outlined"
					/>
				</Stack>
			</div>
			<div className={styles.footer}>
				<Stack
					direction="row"
					spacing={2}
				>
					<Button
						type="submit"
						variant="contained"
						disabled={disabled}
						onClick={save}
						color="success"
					>
						{t("locations.add.save")}
					</Button>
					<Button
						variant="text"
						onClick={cancel}
						color="success"
					>
						{t("locations.add.cancel")}
					</Button>
				</Stack>
			</div>
		</div>
	);
}
