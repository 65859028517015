import Modal from "components/modal/modal";

import Options, { Option } from "components/options/options";
import Svg from "components/svg/svg";
import { RightPanel } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { getCounts, getCheckedCounts } from "helpers/helpers";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LocationsSvg } from "../../assets/icon_locations.svg";

import styles from "./analyse-modal.module.scss";
import SelectLayers from "./select-layers";

function AnalyseModal() {
	const [context, dispatch] = useContext(AppContext);
	const [option, setOption] = useState<Option>(Option.HAZARD);
	const [t] = useTranslation();

	const hazardButtons =
		context?.layerList
			?.map((e) => e.children)
			.flat()
			.map((e) => ({
				id: e.id,
				title: e.name,
				icon: Svg({ icon: e.id }),
			})) || [];

	//   const hazardButtons = [
	//     { title: t("perils.hazard.flood"), icon: FloodSvg },
	//     { title: t("perils.hazard.floodSR"), icon: FloodSRSvg },
	//     { title: t("perils.hazard.storm"), icon: StormSvg },
	//     { title: t("perils.hazard.pluvial"), icon: PluvialFloodSvg },
	//     { title: t("perils.hazard.earthquakeLocal"), icon: EarthQkSoilSvg },
	//     { title: t("perils.hazard.earthquakeRock"), icon: EarthQuakeBedrockSvg },
	//     { title: t("perils.hazard.tsunami"), icon: TsunamiSvg },
	//     { title: t("perils.hazard.windstorm"), icon: WindstormSvg },
	//     { title: t("perils.hazard.hailstorm"), icon: HailstormSvg },
	//     { title: t("perils.hazard.tornado"), icon: TornadoSvg },
	//     { title: t("perils.hazard.landslide"), icon: LandslideSvg },
	//     { title: t("perils.hazard.wildfire"), icon: WildfireSvg },
	//     { title: t("perils.hazard.lightning"), icon: LightningSvg },
	//     { title: t("perils.hazard.vulcano"), icon: VolcanoSvg },
	//     { title: t("perils.hazard.bush"), icon: BushSvg },
	//     { title: t("perils.hazard.france"), icon: SubsidenceSvg },
	//   ];

	const climateButtons = context?.crsLayerList?.map((e) => ({
		id: e.id,
		title: e.name,
		icon: Svg({ icon: e.id }) || [],
	})) || [];

	// const climateButtons = hazardButtons;

	const close = (): void => {
		dispatch({ type: AppAction.setAnalyseModal, payload: false });
	};

	return (
		<Modal
			header={t("analyseModal.analyseLocations")}
			opened={context.analyseModal}
			onClose={close}
		>
			<div className={styles.analyse}>
				<div>
					<div className={styles.header}>
						<LocationsSvg className={styles.image} />
						<div>
							{context.rightPanel === RightPanel.Single ? (
								<div className="ml-1">{context.searchLocationInfo[context.language].formatted_address}</div>
							) : (
								context.currentMultipleLocations && <div>
									<div className="ml-3 pb-2">{context.currentMultipleLocations!.name}</div>
									<div className="ml-3">
										{getCheckedCounts(context.currentMultipleLocations!.items)}/{context.currentMultipleLocations?.itemCount}
										{t("analyseModal.locations")}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="mt-5 mb-2">
					<Options
						selected={option}
						onChange={setOption}
					/>
				</div>
				<div className={styles.tabContent}>
					{option === Option.HAZARD ? (
						<SelectLayers
							index={0}
							buttons={hazardButtons}
						/>
					) : (
						<SelectLayers
							index={1}
							buttons={climateButtons}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
}

export default AnalyseModal;
