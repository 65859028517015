import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
 
interface ChartProps {
  option: any;
}
 
const Chart: React.FC<ChartProps> = ({ option }) => {
  const chartRef = useRef<HTMLDivElement>(null);
 
  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current!);
    chartInstance.setOption(option);
 
    return () => {
      chartInstance.dispose(); // 清理资源
    };
  }, [option]);
 
  return <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>;
};
 
export default Chart;