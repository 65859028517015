import Icon from "components/icon/icon";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext, getMultipleLocations } from "helpers/context";
import { getCounts, getCheckedCounts } from "helpers/helpers";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as MapPinSvg } from "assets/icon_map_pin.svg";
import styles from "./data-sets.module.scss";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ReactComponent as CreateIcon } from "assets/location_set_creat.svg";
import { ReactComponent as ImportIcon } from "assets/location_set_import.svg";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { deleteLocationsets, getLocationsetsItems } from "api/layer"

interface Props {
  searchText: string;
}

function DataSets(props: Props) {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const openDataPoints = async (locationSet: any) => {
    if (locationSet.items === null || locationSet.items.length === 0) {
      let res = await getLocationsetsItems(locationSet.id)
      let multipleLocations = context.multipleLocations!.map((location: any) => {
        if (location.id === locationSet.id) {
          location = res.data;
        }
        return location;
      })

      dispatch({ type: AppAction.setCurrentMultipleLocations, payload: res.data });
      dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
      
      if (res.data.items.length > 0) {
        dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
        dispatch({ type: AppAction.setMultipleLocationsEdit, payload: true });
      } else {
        dispatch({
          type: AppAction.setLeftPanel,
          payload: LeftPanel.AddLocation
        });
      }
    } else {
      dispatch({ type: AppAction.setMultipleLocationsEdit, payload: true });
    }
  };

  const handleChangeLocationSet = (locationSet: any) => {
    if (locationSet.id === context.currentMultipleLocations?.id) {
      dispatch({ type: AppAction.setCurrentMultipleLocations, payload: undefined });
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
    } else {
      dispatch({ type: AppAction.setCurrentMultipleLocations, payload: locationSet });
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
    }
  };

  const deleteSet = (locationSet: any, index: number): void => {
    if (locationSet.id === context.currentMultipleLocations?.id) {
      dispatch({ type: AppAction.setCurrentMultipleLocations, payload: undefined });
    }
    deleteLocationsets(locationSet.id).then(() => {
      context.multipleLocations?.splice(index, 1);
      dispatch({ type: AppAction.setMultipleLocations, payload: context.multipleLocations });
    })
  };

  const importLocations = (): void => {
    dispatch({ type: AppAction.setImportMenu, payload: true });
  };

  const createSet = (): void => {
    dispatch({ type: AppAction.setCreateModal, payload: true });
  };

  return (
    <div className={styles.sets}>
      <Paper sx={{ width: '100%', overflow: 'hidden', height: '556px', position: 'relative', boxShadow: 'none', flex: 1 }} className={styles.table}>
        <TableContainer sx={{ height: '100%', paddingBottom: '56px' }}>
          <RadioGroup>
            <Table stickyHeader aria-label="sticky  table" size="small">
              <TableHead>
                <TableRow className={styles.row}>
                  <TableCell
                    align="center"
                    style={{ width: 45 }}
                  >&nbsp;</TableCell>
                  <TableCell
                    align="center"
                    style={{ minWidth: 60 }}
                  >{t("locations.table.type")}</TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: 186 }}
                  >{t("locations.table.name")}</TableCell>
                  <TableCell

                    align="right"
                    style={{ minWidth: 123 }}
                  >
                    {t("locations.table.locations")}
                    <br />
                    {t("locations.table.selected")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ minWidth: 150 }}
                  >{t("locations.table.date")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {context.multipleLocations && context.multipleLocations.map((locationSet, index) => (
                  (props.searchText === "" || locationSet.name.toLowerCase().includes(props.searchText.toLowerCase())) && <TableRow
                    key={index}
                    selected={context.currentMultipleLocations && (context.currentMultipleLocations.id === locationSet.id)}
                    onClick={() => openDataPoints(locationSet)}
                    className={styles.row}
                    sx={{
                      '&.Mui-selected,&.Mui-selected:hover': {
                        backgroundColor: 'rgba(14, 138, 63, 0.08)!important'
                      },
                      '&.Mui-selected .delete-set': {
                        background: 'linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #ecf6f0 24px)!important'
                      }
                    }}
                  >
                    <TableCell style={{ padding: 0 }}
                      align="center">
                      <Radio
                        checked={context.currentMultipleLocations ? (context.currentMultipleLocations.id === locationSet.id) : false}
                        value={locationSet.id}
                        color="success"
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleChangeLocationSet(locationSet)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center">
                      <MapPinSvg className={styles.icon} />
                    </TableCell>
                    <TableCell align="left">{locationSet.name}</TableCell>
                    {/* <TableCell
                      align="right">0/{locationSet.itemCount}</TableCell> */}
                    <TableCell align="right">{getCheckedCounts(locationSet.items)}/{locationSet.itemCount}</TableCell>
                    <TableCell
                      align="center">{locationSet.createTime}</TableCell>
                    <TableCell style={{ padding: 0 }}>
                      <div className={styles.delete}>
                        <div
                          className={`${styles.icons} delete-set`}
                          onClick={(evt) => evt.stopPropagation()}
                        >
                          <Icon icon="delete" onClick={() => deleteSet(locationSet, index)} />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </RadioGroup>
          <div className={styles.pagination}>
            items in list : {context.multipleLocations?.length} of {context.multipleLocations?.length}
          </div>
        </TableContainer>
      </Paper>
      <div className={styles.footer}>
        <Stack
          direction="row"
          spacing={2}
        >
          <Button
            onClick={importLocations}
            variant="contained"
            color="success"
            endIcon={<ImportIcon style={{ width: "18px", height: "18px", fill: "white" }} />}
          >
            {t("locationSet.importLocations")}
          </Button>
          <Button
            onClick={createSet}
            variant="outlined"
            color="success"
            endIcon={<CreateIcon style={{ width: "18px", height: "18px", fill: "green" }} />}
          >
            {t("locationSet.createSet")}
          </Button>
        </Stack>
      </div>
    </div >
  );
}

export default DataSets;
