import Icon from "components/icon/icon";
import Input from "components/input/input";
import { LeftPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel, getMultipleLocations } from "helpers/context";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DataSets from "./data-sets/data-sets";
import Import from "./import/import";
import styles from "./locations.module.scss";
import { ReactComponent as SpreadSvg } from 'assets/spread.svg';
import { ReactComponent as NarrowSvg } from 'assets/narrow.svg';


function Locations() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();
  const [multipleLocationsSearch, setMultipleLocationsSearch] = useState("");

  const handleChangeLocationsSize = () => {
    dispatch({ type: AppAction.setLocationsSize, payload: !context.locationsSize });
  }


  return (
    <div className={styles.locations} style={{ width: context.locationsSize ? '800px' : '400px' }}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div>{t("locations.title")}</div>
          <div>
            <Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
          </div>
        </div>
        {context.multipleLocations && (
          <div className="flex items-center justify-between mx-[10px] mb-[16px]">
            <div className={styles.search}>
              <Input
                icon="search"
                placeholder={t("locations.searchPlaceholder")}
                onChange={(e) => { setMultipleLocationsSearch(e.target.value) }}
                value={multipleLocationsSearch}
              />
            </div>
            <div className={styles.switch} onClick={handleChangeLocationsSize}>
              {context.locationsSize ? <NarrowSvg /> : <SpreadSvg />}
            </div>
          </div>
        )}
      </div>

      {context.multipleLocationsLoadings ?
        <div className={styles.loading}>
          <div className="loading loading-spinner loading-lg"></div>
          <div>{t("layers.loading")}</div>
        </div> :
        (context.multipleLocations!.length) > 0 ? <DataSets searchText={multipleLocationsSearch} /> : <Import />
      }
    </div>
  );
}

export default Locations;
