import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "components/button/button";
import Modal from "components/modal/modal";
import { RightPanel, zoomLevelDefault } from "helpers/constants";
import { locationSourceOption } from "helpers/models";
import { AppAction, AppContext, getMultipleLocations } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./create-modal.module.scss";
import { addLocationsets } from "api/layer"

// const options = ["Latitude/Longitude", "Address"];


function CreateModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const options: locationSourceOption[] = [{ label: "Latitude/Longitude", value: "latlng" }];
	const [selectValue, setSelectValue] = useState<locationSourceOption | null>(options[0]);
	const [inputValue, setInputValue] = useState("");

	const close = (): void => {
		dispatch({ type: AppAction.setCreateModal, payload: false });
	};

	const loadData = (): void => {
		let params = {
			name: inputValue,
			locationSource: selectValue!.value
		}

		addLocationsets(params).then(async result => {
			await getMultipleLocations(dispatch)
			dispatch({ type: AppAction.setSingleLocation, payload: undefined });
			dispatch({ type: AppAction.setSingleLocationAnalysed, payload: undefined });
			close();
		})
	};

	return (
		<Modal
			header={t("locations.createModal.title")}
			opened={context.createModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<Stack
					spacing={3}
					sx={{ width: 300 }}
				>
					<TextField
						required
						id="outlined-required"
						label={t("locations.createModal.text1")}
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
					/>
					<Autocomplete
						disablePortal
						value={selectValue}
						onChange={(event, newValue) => {
							setSelectValue(newValue);
						}}
						id="controllable-states-demo"
						options={options}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								label={t("locations.createModal.text2")}
							/>
						)}
					/>
				</Stack>
			</div>
			<div className={styles.buttons}>
				<Button
					onClick={close}
					type="secondary"
				>
					{t("locations.createModal.cancel")}
				</Button>
				&nbsp;&nbsp;
				<Button onClick={loadData}>{t("locations.createModal.create")}</Button>
			</div>
		</Modal>
	);
}

export default CreateModal;
