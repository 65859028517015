import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Icon from "components/icon/icon";
import { LeftPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel, getMultipleLocations } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./edit-location-set.module.scss";
import Autocomplete from "@mui/material/Autocomplete";
import { locationSourceOption, LocationData } from "helpers/models";
import { patchLocationsets, deleteLocationsets } from "api/layer"

export default function EditLocationSet() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [currentMultipleLocations, setCurrentMultipleLocations] = useState(context.currentMultipleLocations!);
	const options: locationSourceOption[] = [{ label: "Latitude/Longitude", value: "latlng" }];
	const [selectValue, setSelectValue] = useState<locationSourceOption | null>(options[0]);

	const save = () => {
		patchLocationsets(currentMultipleLocations.id, currentMultipleLocations).then(res => {
			let multipleLocations = context.multipleLocations?.map((locationSet: LocationData) => {
				if (locationSet.id === currentMultipleLocations.id) {
					locationSet = { ...locationSet, ...currentMultipleLocations }
				}
				return locationSet;
			})

			dispatch({ type: AppAction.setCurrentMultipleLocations, payload: { ...context.currentMultipleLocations, ...currentMultipleLocations } });
			dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
			setLeftPanel(dispatch, LeftPanel.Locations);
		})
	};

	const deleteSet = () => {
		deleteLocationsets(currentMultipleLocations.id).then(() => {
			let multipleLocations = context.multipleLocations?.filter((locationSet: LocationData) => {
				return locationSet.id !== currentMultipleLocations.id;
			})
			dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
			setLeftPanel(dispatch, LeftPanel.Locations);
			dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
			dispatch({ type: AppAction.setCurrentMultipleLocations, payload: null });
		})
	};

	const closeEdit = (): void => {
		setLeftPanel(dispatch, LeftPanel.Locations);
	};

	return (
		<div className={styles.edit}>
			<div className={styles.header}>

				<div className={styles.title}>
					<div className="flex items-center">
						<Icon icon="arrow_back" onClick={closeEdit} />
						<span>{t("locationSet.edit.title")}</span>
					</div>
					<div>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<Stack spacing={{ xs: 1, sm: 2 }}>
					<TextField
						required
						sx={{ width: 340 }}
						value={currentMultipleLocations.name}
						onChange={(e) => setCurrentMultipleLocations({ ...currentMultipleLocations, name: e.target.value })}
						label={t("locationSet.edit.Location_set_name")}
						variant="outlined"
					/>
					<div>{t("locationSet.edit.Location_display_options")}</div>
					<Autocomplete
						disablePortal
						value={selectValue}
						onChange={(event, newValue) => {
							setSelectValue(newValue);
						}}
						id="controllable-states-demo"
						options={options}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								label={t("locations.createModal.text2")}
							/>
						)}
					/>
				</Stack>
			</div>
			<div className={styles.footer}>
				<Stack
					direction="row"
					spacing={2}
				>
					<Button
						variant="contained"
						onClick={save}
						color="success"
					>
						{t("locationSet.edit.save")}
					</Button>
					<Button
						variant="text"
						onClick={deleteSet}
						color="success"
					>
						{t("locationSet.edit.deleteSet")}
					</Button>
				</Stack>
			</div>
		</div>
	);
}
