import Agendas from "containers/agendas/agendas";
import AnalyseModal from "containers/analyse-modal/analyse-modal";
import Layers from "containers/layers/layers";
import LocationSet from "containers/location-set/location-set";
import Locations from "containers/locations/locations";
import Map from "containers/map/amap";
// import Map from "containers/map/amap.js";
// import Map from "containers/map/map";
import MultiLocations from "containers/multi-locations/multi-locations";
import SingleLocation from "containers/single-location/single-location";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppContext, AppAction, getMultipleLocations } from "helpers/context";
import { MapSize } from "helpers/models";
import { useContext, useEffect } from "react";
import { getLayer, getCrsLayer } from "api/layer";

import ImportModal from "containers//multi-import-modal/import-modal/import-modal";
import ImportMenu from "containers//multi-import-modal/import-menu/import-menu";
import CreateModal from "containers//multi-import-modal/create-modal/create-modal";
import ErrorModal from "containers//multi-import-modal/error-modal/error-modal";
import AddLocation from "containers/locations/add/add-location";
import EditLocationSet from "containers/location-set/edit/edit-location-set";

function Home() {
  const [context, dispatch] = useContext(AppContext);


  const groupByCategory = (arr: any) => {
    let newArr: any[] = [];
    const arrF = arr.filter((e: any) => e.id !== "GEO_DistToCoast_Global_SwissRe")
    const arrG = arrF.map((e: any) => {
      if (e.id === "DR_Subsidence_France_SwissRe" || e.id === "WF_DistToBush_AUS_SwissRe") {
        return {
          ...e,
          group: "Additional Local Maps"
        }
      }
      return e;
    })
    arrG.forEach((item: any, i: number) => {
      let index = -1;
      let isExists = newArr.some((newItem, j) => {
        // 这里是判断字段 根据(时间日期) month
        if (item.group === newItem.name) {
          index = j;
          return true;
        }
        return false;
      });
      if (!isExists) {
        newArr.push({
          name: item.group,
          children: [{ ...item, checked: false }],
        });
      } else {
        newArr[index].children.push({ ...item, checked: false });
      }
    });
    return newArr
  }

  const getLayerList = () => {
    getLayer().then((res: any) => {
      const layerList = groupByCategory(res.data)
      dispatch({ type: AppAction.setLayerList, payload: layerList });
    })
  }

  const getCrsRiskTypeList = () => {
    getCrsLayer().then((res: any) => {
      dispatch({ type: AppAction.setCrsLayerList, payload: res.data });
    })
  }

  const getMultipleLocationsList = () => {
    getMultipleLocations(dispatch)
  }

  useEffect(() => {
    getLayerList()
    getCrsRiskTypeList()
    getMultipleLocationsList()
  }, [])


  const mapSize = (): MapSize => {
    if (
      context.leftPanel === LeftPanel.Locations &&
      context.rightPanel !== RightPanel.Empty &&
      context.locationsSize
    ) {
      return MapSize.XS;
    }

    if (
      context.leftPanel !== LeftPanel.Empty &&
      context.rightPanel !== RightPanel.Empty
    ) {
      return MapSize.S;
    }
    if (
      context.leftPanel !== LeftPanel.Empty ||
      context.rightPanel !== RightPanel.Empty
    ) {
      return MapSize.M;
    }
    return MapSize.L;
  };

  return (
    <div className="flex items-center justify-between">
      {context.leftPanel === LeftPanel.Layers && <Layers />}
      {context.leftPanel === LeftPanel.Locations && (context.multipleLocationsEdit ? <LocationSet /> : <Locations />)}
      {(context.leftPanel === LeftPanel.AddLocation || context.leftPanel === LeftPanel.EditLocation) && <AddLocation />}
      {context.leftPanel === LeftPanel.EditLocationSet && <EditLocationSet />}
      <Agendas />
      <ImportMenu />
      <ImportModal />
      <CreateModal />
      <ErrorModal />
      <div className="ml-[1px]">
        <Map size={mapSize()} />
      </div>
      {context.rightPanel === RightPanel.Single && <SingleLocation />}
      {context.rightPanel === RightPanel.Multiple && <MultiLocations />}
      <AnalyseModal />
    </div>
  );
}

export default Home;
