import Button from "components/button/button";
import Modal from "components/modal/modal";
import { RightPanel, zoomLevelDefault } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { locationsData } from "helpers/locations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExcelSvg } from "assets/icon_excel.svg";
import styles from "./import-modal.module.scss";
import * as XLSX from "xlsx";
import { addLocationsets } from "api/layer";

function ImportModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const close = (): void => {
		dispatch({ type: AppAction.setImportModal, payload: false });
	};

	const hanldeChangeUploadFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		debugger;
		const file = event.target.files?.[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();

		reader.onloadend = (progressEvent) => {
			const arrayBuffer = reader.result;
			const options: XLSX.ParsingOptions = { type: "array" };
			const workbook = XLSX.read(arrayBuffer, options);
			const sheetName = workbook.SheetNames;
			const sheet = workbook.Sheets[sheetName[0]];
			const sheet1 = workbook.Sheets[sheetName[1]];
			const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
			const jsonData1 = XLSX.utils.sheet_to_json(sheet1);
			console.log(jsonData, jsonData1);

			if (!jsonData || jsonData.length === 0) {
				return;
			}
			if (!Array.isArray(jsonData[0]) || jsonData[0].length === 0) {
				return;
			}

			let params = {
				name: jsonData[0][1],
			};

			addLocationsets(params).then(async (result) => {
                console.log(result);
			});
		};
		reader.readAsArrayBuffer(file);
	};

	return (
		<Modal
			header={t("locations.modal.title")}
			opened={context.importModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<input
					className={styles.uploadIpt}
					onClick={(e) => e.stopPropagation()}
					onChange={hanldeChangeUploadFile}
					type="file"
				/>
				<div className={styles.import}>
					<div className={styles.upload}>
						<ExcelSvg className={styles.icon} />
						<div className={styles.text1}>{t("locations.modal.text1")}</div>
						<div className={styles.text2}>{t("locations.modal.text2")}</div>
					</div>
					<div className={styles.hint}>{t("locations.modal.hint")}</div>
					{/* href={context.language === 'en' ? "/location-set-import-template.xlsx" : "/地点集导入模版.xlsx"} */}
					<a
						className={styles.link}
						href={`${process.env.PUBLIC_URL}/${context.language === "en" ? "location-set-import-template.xlsx" : "地点集导入模版.xlsx"}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{t("locations.modal.link")}
					</a>
				</div>
			</div>
			<div className={styles.buttons}>
				<Button onClick={close}>{t("locations.modal.cancel")}</Button>
			</div>
		</Modal>
	);
}

export default ImportModal;
