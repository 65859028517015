import Button from "components/button/button";
import Icon from "components/icon/icon";
import Results from "components/results/results";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { getCheckedCounts } from "helpers/helpers";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddLocationSvg } from "assets/icon_add_location.svg";
import { ReactComponent as LocationsPinSvg } from "assets/pin_locations.svg";
import styles from "./multi-locations.module.scss";

function MultiLocations() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const openModal = (): void => {
		dispatch({ type: AppAction.setAnalyseModal, payload: true });
	};

	const closeLocations = (): void => {
		dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
		dispatch({ type: AppAction.setLeftPanel, payload: LeftPanel.Empty });
		dispatch({ type: AppAction.setMapCenter, payload: undefined });
		dispatch({ type: AppAction.setSingleLocation, payload: undefined });
		dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
	};

	function getDate() {
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const date = today.getDate();
		return `${date}/${month}/${year}`;
	}

	const addLocation = () => {
		setLeftPanel(dispatch, LeftPanel.AddLocation);
	};

	return (
		<div className={styles.multiple}>
			<div>
				<div className={styles.title}>
					<div>{context.currentMultipleLocations?.name}</div>
					<div className="flex items-center">
						<div
							className="tooltip z-50"
							data-tip={t("single.addLocation")}
						>
							<div className={styles.icon}>
								<AddLocationSvg onClick={addLocation} />
							</div>
						</div>
						<div>
							<Icon onClick={closeLocations} />
						</div>
					</div>
				</div>
				<div className="flex flex-row justify-items-start p-[16px]">
					<div className={styles.image}>
						<LocationsPinSvg />
					</div>
					<div className={styles.address}>
						<div>
							<div>{t("multiple.createTime")}: {context.currentMultipleLocations?.createTime}</div>
							{context.multipleLocationsAnalysed && (
								<div>
									{t("multiple.analysisDate")}: {getDate()}
								</div>
							)}
							{/* <Button>{t("multiple.details")}</Button> */}
						</div>
					</div>
				</div>
				{context.multipleLocationsAnalysed ?
					<Results /> :
					(<div className={styles.locationCount}>
						{getCheckedCounts(context.currentMultipleLocations!.items)}/{context.currentMultipleLocations?.itemCount} {t("multiple.locations")}
					</div>)
				}
			</div>
			<div className={styles.buttons}>
				{context.multipleLocationsAnalysed && (
					<div className="w-fit mr-3">
						<a
							href="/CatNet_Report_Multiple_Premium.pdf"
							target="_blank"
							rel="noreferrer"
						>
							<Button type="outlined">{t("multiple.buttonDownload")}</Button>
						</a>
					</div>
				)}
				<Button onClick={openModal}>{t("multiple.buttonAnalyse")}</Button>
			</div>
		</div>
	);
}

export default MultiLocations;
