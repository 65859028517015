import { ReactComponent as NotAnalysisSvg } from 'assets/notAnalysis.svg';
import { AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Chart from "components/chart/chart"
import Svg from "components/svg/svg";

export default function MultipleResults() {
    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();
    let results = {
        "tabId": "hazard",
        "results": [
            {
                "results": [
                    {
                        "pointId": "a84f86c9-d210-4570-9893-93631137613c",
                        "layerId": "EQ_LocalSoilCondition_Global_SwissRe",
                        "valueLabel": "Negligible (< 0.014)",
                        "intensity": 1,
                        "details": {
                            "EQ PGA [g]": "0.00",
                            "EQ Hazard": "Negligible (< 0.014)",
                            "EQ MMI": "< IV"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "c57d460d-5279-42ba-9638-863cd81cc4e7",
                        "layerId": "EQ_LocalSoilCondition_Global_SwissRe",
                        "valueLabel": "Moderate (0.085 - 0.160)",
                        "intensity": 4.42,
                        "details": {
                            "EQ PGA [g]": "0.13",
                            "EQ Hazard": "Moderate (0.085 - 0.160)",
                            "EQ MMI": "VI"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "948cbc07-a7fe-420e-8271-a82673728e40",
                        "layerId": "EQ_LocalSoilCondition_Global_SwissRe",
                        "valueLabel": "Moderate (0.085 - 0.160)",
                        "intensity": 4.42,
                        "details": {
                            "EQ PGA [g]": "0.13",
                            "EQ Hazard": "Moderate (0.085 - 0.160)",
                            "EQ MMI": "VI"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "74bc9850-2903-4551-8a3d-4244992f3d46",
                        "layerId": "EQ_LocalSoilCondition_Global_SwissRe",
                        "valueLabel": "Low (0.046 - 0.084)",
                        "intensity": 1.7,
                        "details": {
                            "EQ PGA [g]": "0.05",
                            "EQ Hazard": "Low (0.046 - 0.084)",
                            "EQ MMI": "V"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a88aa4db-442b-4025-adb3-24b19b278b9b",
                        "layerId": "EQ_LocalSoilCondition_Global_SwissRe",
                        "valueLabel": "Negligible (< 0.014)",
                        "intensity": 1,
                        "details": {
                            "EQ PGA [g]": "0.00",
                            "EQ Hazard": "Negligible (< 0.014)",
                            "EQ MMI": "< IV"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a84f86c9-d210-4570-9893-93631137613c",
                        "layerId": "heat_wave",
                        "valueLabel": "Very Low (<0.1)",
                        "intensity": 1,
                        "details": {
                            "heat_wave Days per year": "<0.1",
                            "heat_wave Hazard": "Very Low (<0.1)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "c57d460d-5279-42ba-9638-863cd81cc4e7",
                        "layerId": "heat_wave",
                        "valueLabel": "Moderate (0.2 - 0.4)",
                        "intensity": 4.7,
                        "details": {
                            "heat_wave Days per year": "0.2 - 0.4",
                            "heat_wave Hazard": "Moderate (0.2 - 0.4)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "948cbc07-a7fe-420e-8271-a82673728e40",
                        "layerId": "heat_wave",
                        "valueLabel": "Moderate (0.2 - 0.4)",
                        "intensity": 4.7,
                        "details": {
                            "heat_wave Days per year": "0.2 - 0.4",
                            "heat_wave Hazard": "Moderate (0.2 - 0.4)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "74bc9850-2903-4551-8a3d-4244992f3d46",
                        "layerId": "heat_wave",
                        "valueLabel": "High (0.6 - 0.8)",
                        "intensity": 7.8,
                        "details": {
                            "heat_wave Days per year": "0.6 - 0.8",
                            "heat_wave Hazard": "High (0.6 - 0.8)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a88aa4db-442b-4025-adb3-24b19b278b9b",
                        "layerId": "heat_wave",
                        "valueLabel": "Very Low (<0.1)",
                        "intensity": 1,
                        "details": {
                            "heat_wave Days per year": "<0.1",
                            "heat_wave Hazard": "Very Low (<0.1)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a84f86c9-d210-4570-9893-93631137613c",
                        "layerId": "GEO_DistToCoast_Global_SwissRe",
                        "valueLabel": "Outside",
                        "intensity": 1,
                        "details": {
                            "GEO_DistToCoast_Global_SwissRe Return Period": "Outside"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "c57d460d-5279-42ba-9638-863cd81cc4e7",
                        "layerId": "GEO_DistToCoast_Global_SwissRe",
                        "valueLabel": "Outside",
                        "intensity": 1,
                        "details": {
                            "GEO_DistToCoast_Global_SwissRe Return Period": "Outside"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "948cbc07-a7fe-420e-8271-a82673728e40",
                        "layerId": "GEO_DistToCoast_Global_SwissRe",
                        "valueLabel": "Outside",
                        "intensity": 1,
                        "details": {
                            "GEO_DistToCoast_Global_SwissRe Return Period": "Outside"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "74bc9850-2903-4551-8a3d-4244992f3d46",
                        "layerId": "GEO_DistToCoast_Global_SwissRe",
                        "valueLabel": "Outside",
                        "intensity": 1,
                        "details": {
                            "GEO_DistToCoast_Global_SwissRe Return Period": "Outside"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a88aa4db-442b-4025-adb3-24b19b278b9b",
                        "layerId": "GEO_DistToCoast_Global_SwissRe",
                        "valueLabel": "Outside",
                        "intensity": 1,
                        "details": {
                            "GEO_DistToCoast_Global_SwissRe Return Period": "Outside"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a84f86c9-d210-4570-9893-93631137613c",
                        "layerId": "wind",
                        "valueLabel": "Low (20-25 m/s)",
                        "intensity": 1,
                        "details": {
                            "Storm Peak Gust [m/s]": "20.00",
                            "Storm Hazard": "Low (20-25 m/s)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "c57d460d-5279-42ba-9638-863cd81cc4e7",
                        "layerId": "wind",
                        "valueLabel": "Low (25-30 m/s)",
                        "intensity": 1,
                        "details": {
                            "Storm Peak Gust [m/s]": "26.00",
                            "Storm Hazard": "Low (25-30 m/s)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "948cbc07-a7fe-420e-8271-a82673728e40",
                        "layerId": "wind",
                        "valueLabel": "Low (25-30 m/s)",
                        "intensity": 1,
                        "details": {
                            "Storm Peak Gust [m/s]": "26.00",
                            "Storm Hazard": "Low (25-30 m/s)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "74bc9850-2903-4551-8a3d-4244992f3d46",
                        "layerId": "wind",
                        "valueLabel": "Low (20-25 m/s)",
                        "intensity": 1,
                        "details": {
                            "Storm Peak Gust [m/s]": "20.00",
                            "Storm Hazard": "Low (20-25 m/s)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a88aa4db-442b-4025-adb3-24b19b278b9b",
                        "layerId": "wind",
                        "valueLabel": "Very Low (<20 m/s)",
                        "intensity": 1,
                        "details": {
                            "Storm Peak Gust [m/s]": "19.00",
                            "Storm Hazard": "Very Low (<20 m/s)"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a84f86c9-d210-4570-9893-93631137613c",
                        "layerId": "sea_level_rise",
                        "valueLabel": "Negligible (< 0.014)",
                        "intensity": 1,
                        "details": {
                            "EQ PGA [g]": "0.00",
                            "EQ Hazard": "Negligible (< 0.014)",
                            "EQ MMI": "< IV"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "c57d460d-5279-42ba-9638-863cd81cc4e7",
                        "layerId": "sea_level_rise",
                        "valueLabel": "Moderate (0.085 - 0.160)",
                        "intensity": 3.62,
                        "details": {
                            "EQ PGA [g]": "0.10",
                            "EQ Hazard": "Moderate (0.085 - 0.160)",
                            "EQ MMI": "VI"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "948cbc07-a7fe-420e-8271-a82673728e40",
                        "layerId": "sea_level_rise",
                        "valueLabel": "Moderate (0.085 - 0.160)",
                        "intensity": 3.62,
                        "details": {
                            "EQ PGA [g]": "0.10",
                            "EQ Hazard": "Moderate (0.085 - 0.160)",
                            "EQ MMI": "VI"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "74bc9850-2903-4551-8a3d-4244992f3d46",
                        "layerId": "sea_level_rise",
                        "valueLabel": "Very Low (0.014 - 0.045)",
                        "intensity": 1.11,
                        "details": {
                            "EQ PGA [g]": "0.04",
                            "EQ Hazard": "Very Low (0.014 - 0.045)",
                            "EQ MMI": "IV"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a88aa4db-442b-4025-adb3-24b19b278b9b",
                        "layerId": "sea_level_rise",
                        "valueLabel": "Negligible (< 0.014)",
                        "intensity": 1,
                        "details": {
                            "EQ PGA [g]": "0.00",
                            "EQ Hazard": "Negligible (< 0.014)",
                            "EQ MMI": "< IV"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a84f86c9-d210-4570-9893-93631137613c",
                        "layerId": "VolcanoSvg",
                        "valueLabel": "High",
                        "intensity": 6.4,
                        "details": {
                            "Wildfire Susceptibility": "6.42",
                            "Wildfire Hazard": "High"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "c57d460d-5279-42ba-9638-863cd81cc4e7",
                        "layerId": "VolcanoSvg",
                        "valueLabel": "Negligible",
                        "intensity": 1,
                        "details": {
                            "Wildfire Susceptibility": "1.00",
                            "Wildfire Hazard": "Negligible"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "948cbc07-a7fe-420e-8271-a82673728e40",
                        "layerId": "VolcanoSvg",
                        "valueLabel": "Negligible",
                        "intensity": 1,
                        "details": {
                            "Wildfire Susceptibility": "1.00",
                            "Wildfire Hazard": "Negligible"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "74bc9850-2903-4551-8a3d-4244992f3d46",
                        "layerId": "VolcanoSvg",
                        "valueLabel": "Negligible",
                        "intensity": 2,
                        "details": {
                            "Wildfire Susceptibility": "1.97",
                            "Wildfire Hazard": "Negligible"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    },
                    {
                        "pointId": "a88aa4db-442b-4025-adb3-24b19b278b9b",
                        "layerId": "VolcanoSvg",
                        "valueLabel": "Extreme",
                        "intensity": 8.8,
                        "details": {
                            "Wildfire Susceptibility": "8.85",
                            "Wildfire Hazard": "Extreme"
                        },
                        "geometry": "",
                        "fathomAdditionalDetails": {}
                    }
                ],
                "propertyResults": [],
                "errors": [],
                "queryLayers": [
                    "EQ_LocalSoilCondition_Global_SwissRe,sea_level_rise,GEO_DistToCoast_Global_SwissRe,wind,heat_wave,VolcanoSvg"
                ],
                "isSingleAnalysis": false
            }
        ],
        "errors": []
    }

    let legendResult = {
        "legends": [
            {
                "layerId": "GEOPORTAL:sea_level_rise_2023",
                "title": "EQ_LocalSoilCondition_Global_SwissRe",
                "subtitle": "475y Peak Ground Acceleration (g)",
                "rules": [
                    {
                        "title": "Negligible (< 0.014)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#F2FCF2",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very Low (0.014 - 0.045)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#C2E529",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Low (0.046 - 0.084)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FAFA24",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Moderate (0.085 - 0.160)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E9C418",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Significant (0.161 - 0.29)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E98C18",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "High (0.291 - 0.400)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E93A18",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very High (0.401 - 0.550)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#CA0411",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Extreme (0.551 - 0.750)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#A50010",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very Extreme (> 0.750)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#70000B",
                                    "opacity": 1
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "layerId": "GEOPORTAL_SHARED:sea_level_rise",
                "title": "sea_level_rise",
                "subtitle": "475y Peak Ground Acceleration (g)",
                "rules": [
                    {
                        "title": "Negligible (< 0.014)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#F2FCF2",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very Low (0.014 - 0.045)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#C2E529",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Low (0.046 - 0.084)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FAFA24",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Moderate (0.085 - 0.160)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E9C418",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Significant (0.161 - 0.29)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E98C18",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "High (0.291 - 0.400)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E93A18",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very High (0.401 - 0.550)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#CA0411",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Extreme (0.551 - 0.750)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#A50010",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very Extreme (> 0.750)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#70000B",
                                    "opacity": 1
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "layerId": "GEOPORTAL_SHARED:wind",
                "title": "wind",
                "subtitle": "50y Peak Gust",
                "rules": [
                    {
                        "title": "Very Low (<20 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#8F8FFF",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Low (20-25 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#9CBBFF",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Low (25-30 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#9CDEFF",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Moderate (30-35 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#80FFFF",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Moderate (35-40 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#C7FFDF",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Significant (40-50 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FFFF99",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "High (50-60 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FFDD99",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very High (60-70 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FFBB99",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Extreme (>70 m/s)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FF9999",
                                    "opacity": 1
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "layerId": "CATNET:GEO_DistToCoast_Global_SwissRe",
                "title": "GEO_DistToCoast_Global_SwissRe",
                "subtitle": "Return Period [years]",
                "rules": [
                    {
                        "title": "No Data",
                        "symbolizers": [
                            {
                                "polygon": {
                                    "stroke": "#ffffff",
                                    "strokeOpacity": 1,
                                    "fill": "#FFFFFF",
                                    "fillOpacity": 0,
                                    "strokeDashArray": []
                                }
                            }
                        ],
                        "imageBase64": ""
                    },
                    {
                        "title": "10000 years",
                        "symbolizers": [
                            {
                                "polygon": {
                                    "stroke": "#bee8ff",
                                    "strokeOpacity": 1,
                                    "fill": "#BEE8FF",
                                    "fillOpacity": 1,
                                    "strokeDashArray": []
                                }
                            }
                        ],
                        "imageBase64": ""
                    },
                    {
                        "title": "5000 years",
                        "symbolizers": [
                            {
                                "polygon": {
                                    "stroke": "#73dfff",
                                    "strokeOpacity": 1,
                                    "fill": "#73DFFF",
                                    "fillOpacity": 1,
                                    "strokeDashArray": []
                                }
                            }
                        ],
                        "imageBase64": ""
                    },
                    {
                        "title": "2500 years",
                        "symbolizers": [
                            {
                                "polygon": {
                                    "stroke": "#00c5ff",
                                    "strokeOpacity": 1,
                                    "fill": "#00C5FF",
                                    "fillOpacity": 1,
                                    "strokeDashArray": []
                                }
                            }
                        ],
                        "imageBase64": ""
                    },
                    {
                        "title": "1000 years",
                        "symbolizers": [
                            {
                                "polygon": {
                                    "stroke": "#0084a8",
                                    "strokeOpacity": 1,
                                    "fill": "#0084A8",
                                    "fillOpacity": 1,
                                    "strokeDashArray": []
                                }
                            }
                        ],
                        "imageBase64": ""
                    },
                    {
                        "title": "500 years",
                        "symbolizers": [
                            {
                                "polygon": {
                                    "stroke": "#004c73",
                                    "strokeOpacity": 1,
                                    "fill": "#004C73",
                                    "fillOpacity": 1,
                                    "strokeDashArray": []
                                }
                            }
                        ],
                        "imageBase64": ""
                    }
                ]
            },
            {
                "layerId": "GEOPORTAL_SHARED:heat_wave",
                "title": "heat_wave",
                "subtitle": "heat_wave Days (>2cm) per 2500 km² and Year",
                "rules": [
                    {
                        "title": "No Data",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FFFFFF",
                                    "opacity": 0
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very Low (<0.1)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FFFFF2",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Low (0.1 - 0.2)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FCFAAA",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Moderate (0.2 - 0.4)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FFFC52",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Significant (0.4 - 0.6)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FFBF00",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "High (0.6 - 0.8)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FC8700",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very High (0.8 - 1.0)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FF0000",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Extreme (>1.0)",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E60000",
                                    "opacity": 1
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "layerId": "GEOPORTAL_SHARED:VolcanoSvg",
                "title": "VolcanoSvg",
                "subtitle": "",
                "rules": [
                    {
                        "title": "Negligible",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#F2FCF2",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very Low",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#C2E529",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Low",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#FAFA24",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Moderate",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E9C418",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Significant",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E98C18",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "High",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#E93A18",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very High",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#CA0411",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Extreme",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#A50010",
                                    "opacity": 1
                                }
                            }
                        ]
                    },
                    {
                        "title": "Very Extreme",
                        "symbolizers": [
                            {
                                "raster": {
                                    "color": "#70000B",
                                    "opacity": 1
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }

    let arr: any = results.results[0].results.sort((a: any, b: any) => {
        return b.intensity - a.intensity;
    });

    let data: any = [];
    legendResult.legends.forEach((legend, index) => {
        arr.forEach((item: any) => {
            if (item.layerId === legend.title) {
                if (item.valueLabel === "Outside" || item.valueLabel === "No Data" || item.valueLabel === "No Observation") {
                    if (data[index]) {
                        data[index].push({
                            ...item,
                            value: 1,
                            itemStyle: {
                                color: "grey",
                                opacity: "0.5",
                            }
                        })
                    } else {
                        data[index] = [{
                            ...item,
                            value: 1,
                            itemStyle: {
                                color: "grey",
                                opacity: "0.5",
                            }
                        }]
                    }
                } else {
                    legend.rules.forEach((rules: any) => {
                        if (item.valueLabel === rules.title) {
                            const raster = rules.symbolizers[0]?.raster?.color;
                            const polygon = rules.symbolizers[0]?.polygon?.fill;
                            const rasterOpacity = rules.symbolizers[0]?.raster?.opacity;
                            const polygonOpacity = rules.symbolizers[0]?.polygon?.fillOpacity;
                            const color = raster || polygon;
                            const opacity = rasterOpacity || polygonOpacity;
                            if (data[index]) {
                                data[index].push({
                                    ...item,
                                    value: 1,
                                    itemStyle: {
                                        color: color,
                                        opacity: opacity,
                                    }
                                })
                            } else {
                                data[index] = [{
                                    ...item,
                                    value: 1,
                                    itemStyle: {
                                        color: color,
                                        opacity: opacity,
                                    }
                                }]
                            }
                        }
                    })
                }
            }
        })
    });

    let series: any = [];
    let Xnum = data[0].length;
    let Ynum = data.length;
    for (let i = 0; i < Xnum; i++) {
        let dataArr = [];
        for (let j = 0; j < Ynum; j++) {
            if (data[j]) {
                dataArr.unshift(data[j][i]);
            }
        }
        series.push({
            name: i,
            type: 'bar',
            stack: 'total',
            barWidth: 30,
            emphasis: {
                focus: 'self'
            },
            data: dataArr
        })
    }


    console.log(series, 'data');

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        grid: {
            left: 10,
            right: 10,
            bottom: 10,
            top: 10,
            containLabel: true
        },
        xAxis: {
            type: 'value',
            axisLine: {
                show: true
            }
        },
        yAxis: {
            type: 'category',
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            axisLine: {
                show: true,
                onZero: true,
                lineStyle: {
                    color: "#fff",
                    shadowOffsetX: -1,
                    shadowColor: "#333"
                }

            },
        },
        series: series
    };
    console.log(option, 'option');


    // if (!context.analysisData) {
    //     return (
    //         <div className="text-center">
    //             <NotAnalysisSvg className="w-[60px] h-[60px] mx-auto  mb-4" />
    //             <div style={{ color: "var(--gray-text)" }}>{t("single.notAnalysis")}</div>
    //         </div>);
    // }

    // const legendItems = context.analysisData?.legendItems;
    // const analysisItems = context.analysisData?.analysisItems;

    // if ((typeof legendItems === "string") || (typeof analysisItems === "string")) {
    //     return (
    //         <div className="text-center">
    //             <div className="loading loading-spinner loading-lg"></div>
    //             <div>{t("single.alalyseLoadingContent")}</div>
    //         </div>);
    // }

    return (
        <div className="text-xs">
            <div style={{ width: '100%', height: '400px' }}>
        
                <Chart option={option} />
            </div>
        </div>
    );
}
