import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImportSvg } from "../../../assets/icon_import.svg";
import { ReactComponent as CreateSvg } from "../../../assets/icon_create.svg";
import styles from "./import.module.scss";

function Import() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const openImportMenu = () => {
    dispatch({ type: AppAction.setImportMenu, payload: true });
  };

  const openCreateModal = (): void => {
    dispatch({ type: AppAction.setCreateModal, payload: true });
  };

  return (
    <div className={styles.import}>
      <div
        className={styles.button}
        onClick={openImportMenu}
      >
        <ImportSvg className={styles.icon} />
        <div className={styles.text}>{t("locations.import")}</div>
      </div>&nbsp;or&nbsp;
      <div className={styles.button} onClick={openCreateModal}>
        <CreateSvg className={styles.icon} />
        <div className={styles.text}>{t("locations.create")}</div>
      </div>

    </div>
  );
}

export default Import;
