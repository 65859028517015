import Button from "components/button/button";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./error-modal.module.scss";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function ImportModal() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const close = (): void => {
    dispatch({ type: AppAction.setErrorModal, payload: false });
  };



  return (
    <Modal
      header={'The import file contains 3 row(s) with errors or missing data'}
      opened={context.errorModal}
      onClose={close}
    >
      <div className={styles.modal}>
        <div className={styles.info}>
          <p>Please fix the errors and re-upload the import file or skip the locations with errors and proceedwith creating the Location set.</p>
        </div>
        <Paper sx={{ width: '100%', overflow: 'auto', boxShadow: "none", flex: 1 }}>
          <Table size='small' stickyHeader aria-label="sticky  table" >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '150px' }}>Row number</TableCell>
                <TableCell>Error message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
            >
              <TableRow>
                <TableCell>23</TableCell>
                <TableCell>cawcawcawce</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3</TableCell>
                <TableCell>Eacwcawca</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>43</TableCell>
                <TableCell>Error message</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>43</TableCell>
                <TableCell>Error message</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>43</TableCell>
                <TableCell>Error message</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>43</TableCell>
                <TableCell>Error message</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>43</TableCell>
                <TableCell>Error message</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>43</TableCell>
                <TableCell>Error message</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </div>
      <div className={styles.buttons}>
        <Button type="secondary" onClick={close}>
          {t("locations.modal.cancel")}
        </Button>
        <Button type="secondary">REUPLOAD FILE</Button>
        <Button onClick={close}>PROCEED</Button>
      </div>
    </Modal>
  );
}

export default ImportModal;
