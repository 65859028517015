import Checkbox from '@mui/material/Checkbox';
import Icon from "components/icon/icon";
import Input from "components/input/input";
import { LeftPanel, RightPanel, zoomLevelDefault, zoomLevelMedium } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { formatCurrency } from "helpers/helpers";
import { LocationData } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./location-set.module.scss";
import { ReactComponent as SpreadSvg } from 'assets/spread.svg';
import { ReactComponent as NarrowSvg } from 'assets/narrow.svg';
import { ReactComponent as ExportListSvg } from 'assets/exportList.svg';
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import { deleteLocationsetsItems, getLocationsetsItems } from "api/layer"

function LocationSet() {
  const [context, dispatch] = useContext(AppContext);
  const [selected, setSelected] = useState<string>("");
  const [rowCount, setRowCount] = useState(context.currentMultipleLocations!.items?.filter((location: LocationData) => location.checked).length || 0);
  const [multipleLocationsSearch, setMultipleLocationsSearch] = useState("");
  const [t] = useTranslation();

  useEffect(() => {
    if (context.rightPanel === RightPanel.Multiple) {
      setSelected("");
    }
  }, [context.rightPanel]);

  const closeEdit = (): void => {
    dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
  };

  const focusLocation = (location: LocationData): void => {
    if (location.id !== selected) {
      setSelected(location.id);
      dispatch({
        type: AppAction.setMapCenter,
        payload: [location.lon, location.lat],
      });
      dispatch({ type: AppAction.setMapZoom, payload: zoomLevelMedium });
      // dispatch({ type: AppAction.setSingleLocation, payload: location });
      dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
    } else {
      setSelected("");
      dispatch({ type: AppAction.setMapCenter, payload: undefined });
      dispatch({ type: AppAction.setMapZoom, payload: zoomLevelDefault });
      dispatch({ type: AppAction.setSingleLocation, payload: undefined });
      dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
    }
  };

  const toggleLocation = (evt: any, location: any): void => {
    evt.stopPropagation();

    context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((item: LocationData) => {
      if (item.id === location.id) {
        item.checked = !item.checked;
      }
      return item;
    })
    let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
      if (locationSet.id === context.currentMultipleLocations!.id) {
        locationSet.items = context.currentMultipleLocations!.items;
      }
      return locationSet;
    })
    let num: number = context.currentMultipleLocations!.items?.filter((location: LocationData) => location.checked).length || 0
    setRowCount(num)
    dispatch({
      type: AppAction.setCurrentMultipleLocations,
      payload: context.currentMultipleLocations,
    });

    dispatch({
      type: AppAction.setMultipleLocations,
      payload: multipleLocations,
    });
  };

  const patchLocation = (item: any): void => {
    dispatch({
      type: AppAction.setEditMultipleLocation,
      payload: item,
    });

    dispatch({
      type: AppAction.setLeftPanel,
      payload: LeftPanel.EditLocation,
    });
  };

  const deleteLocation = (item: any, index: number): void => {
    deleteLocationsetsItems(context.currentMultipleLocations!.id, item.id).then(async (result) => {
      context.currentMultipleLocations!.items?.splice(index, 1);
      let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
        if (locationSet.id === context.currentMultipleLocations!.id) {
          locationSet.items = context.currentMultipleLocations!.items;
          locationSet.itemCount = context.currentMultipleLocations!.items?.length || 0;
        }
        return locationSet;
      })
      setRowCount(context.currentMultipleLocations!.items?.filter((location: LocationData) => location.checked).length || 0)
      dispatch({
        type: AppAction.setCurrentMultipleLocations,
        payload: context.currentMultipleLocations,
      });
      dispatch({
        type: AppAction.setMultipleLocations,
        payload: multipleLocations,
      });
      if (context.currentMultipleLocations!.items?.length === 0) {
        dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
      }
    })
  };

  const handleChangeLocationsSize = () => {
    dispatch({ type: AppAction.setLocationsSize, payload: !context.locationsSize });
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((location: any) => {
      location.checked = event.target.checked;
      return location;
    });

    let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
      if (locationSet.id === context.currentMultipleLocations!.id) {
        locationSet.items = context.currentMultipleLocations!.items;
      }
      return locationSet;
    })

    dispatch({
      type: AppAction.setCurrentMultipleLocations,
      payload: context.currentMultipleLocations,
    });
    dispatch({
      type: AppAction.setMultipleLocations,
      payload: multipleLocations,
    });

    if (event.target.checked) {
      setRowCount(context.currentMultipleLocations!.items!.length)
    } else {
      setRowCount(0)
    }
  };

  return (
    <div className={styles.set} style={{ width: context.locationsSize ? '800px' : '400px' }}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Icon icon="arrow_back" onClick={closeEdit} />
          <div className="flex items-center">
            <span> {context.currentMultipleLocations!.name}</span>
            <Icon icon="create" onClick={() => setLeftPanel(dispatch, LeftPanel.EditLocationSet)} />
          </div>
          <div className={styles.close}>
            <Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
          </div>
        </div>
        {context.multipleLocations && (
          <div className="flex items-center justify-between mx-[10px] mb-[16px]">
            <div className={styles.search}>
              <Input
                icon="search"
                placeholder={t("locations.searchPlaceholder")}
                onChange={(e) => { setMultipleLocationsSearch(e.target.value) }}
                value={multipleLocationsSearch}
              />
            </div>
            <div className={styles.switch} onClick={handleChangeLocationsSize}>
              {context.locationsSize ? <NarrowSvg /> : <SpreadSvg />}
            </div>
          </div>
        )}
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden', height: '556px', position: 'relative', boxShadow: 'none', flex: 1 }} className={styles.table}>
        <TableContainer sx={{ height: '100%', paddingBottom: '56px' }}>
          <Table stickyHeader aria-label="sticky  table" size="small">
            <TableHead>
              <TableRow className={styles.row}>
                <TableCell style={{ padding: 0, width: 50 }} align="center">
                  <Checkbox
                    color="success"
                    indeterminate={rowCount > 0 && context.currentMultipleLocations!.items!.length !== rowCount}
                    checked={rowCount > 0 && context.currentMultipleLocations!.items!.length === rowCount}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  style={{ width: 50 }}
                >ID</TableCell>
                <TableCell
                  align="left"
                >
                  Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {context.currentMultipleLocations!.items?.map((location: LocationData, index: number) => (
                (multipleLocationsSearch === "" || location.name.toLowerCase().includes(multipleLocationsSearch.toLowerCase())) && <TableRow
                  key={location.id}
                  selected={location.checked}
                  className={styles.row}
                  onClick={() => focusLocation(location)}
                  sx={{
                    '&.Mui-selected,&.Mui-selected&:hover': {
                      backgroundColor: 'rgba(14, 138, 63, 0.08)!important'
                    },
                    '&.Mui-selected .delete-set': {
                      background: 'linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #ecf6f0 24px)!important'
                    }
                  }}>
                  <TableCell style={{ padding: 0 }}
                    align="center">
                    <Checkbox
                      color="success"
                      checked={location.checked}
                      onClick={(evt: any) => toggleLocation(evt, location)}
                    />
                  </TableCell>
                  <TableCell
                    align="left">
                    {location.code}
                  </TableCell>
                  <TableCell align="left">{location.name}</TableCell>
                  <TableCell style={{ padding: 0 }}>
                    <div className={styles.delete}>
                      <div
                        className={`${styles.icons} delete-set`}
                        onClick={(evt) => evt.stopPropagation()}
                      >
                        <Icon icon="create" onClick={() => patchLocation(location)} />
                        <Icon icon="delete" onClick={() => deleteLocation(location, index)} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className={styles.pagination}>
            items in list : {rowCount} of {context.currentMultipleLocations!.items!.length}
          </div>
        </TableContainer>
      </Paper>
      <div className={styles.footer}>
        <Button
          variant="outlined"
          color="success"
          style={{ width: '100%' }}
          endIcon={<ExportListSvg style={{ width: "18px", height: "18px", fill: "green" }} />}
        >
          EXPORT LiST
        </Button>
      </div>
    </div>
  );
}

export default LocationSet;
